import i18n from 'src/locales/i18n';
import { Badge } from 'src/components/badge/badge';
import {
  ClipboardChecklistIcon,
  ClipboardCheckIcon,
  PackageIcon,
  DeliveryIcon,
  AlertTriangleIcon,
  LabelPrinterIcon,
  XCircleIcon,
} from '@shopify/polaris-icons';
import { useGetUsersWithParams } from 'src/api/user_api';
import { chooseLan } from 'src/utils/language_code';
import { SingleSelectAutocomplete } from 'src/components/table/filter/autocomplete';
import { TextFieldFilter } from 'src/components/table/filter/text_field';
import BulkButton from 'src/components/bulk_button';
import { isZeroObject, onlyContains } from 'src/utils/type_check';
import { issueConfirm, confirm } from 'src/components/dialog/confirmation';

import { orderAddressOMSNotifyBulk } from 'src/api/order_api';
import { getRePrintOutbound } from 'src/api/outbound/outbound_api';
import QZPrintFile from 'src/utils/print';

export const OUTSTATUS = {
  PICKING: 'Picking',
  PACKING: 'Packing',
  SHIPPING: 'Shipping',
  SHIPPED: 'Shipped',
  HOLDFORERROR: 'HoldForError',
  ALL: 'All',
  CANCEL: 'Cancel',
};

export const getBadge = (status, children) => {
  if (status === OUTSTATUS.PICKING)
    return (
      <Badge
        tone={'attention'}
        icon={ClipboardChecklistIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.PACKING)
    return (
      <Badge
        tone="warning"
        icon={PackageIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.SHIPPING)
    return (
      <Badge
        tone="info"
        icon={ClipboardCheckIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.SHIPPED)
    return (
      <Badge
        tone="success"
        icon={DeliveryIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.HOLDFORERROR)
    return (
      <Badge
        tone="critical"
        icon={AlertTriangleIcon}
      >
        {children}
      </Badge>
    );
  if (status === OUTSTATUS.CANCEL) return <Badge icon={XCircleIcon}>{children}</Badge>;
  if (status === 'All') return <Badge tone="neutral"> {children} </Badge>;
};

export const tabs = (count) => {
  return [
    {
      label: i18n.t('common:tab.all'),
      value: '',
      onClick: () => {},
      badge: getBadge(OUTSTATUS.ALL, count.allCount),
      dataCount: count.allCount,
    },
    {
      label: i18n.t('common:tab.picking'),
      value: OUTSTATUS.PICKING,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.PICKING, count.pickingCount),
      dataCount: count.pickingCount,
    },
    {
      label: i18n.t('common:tab.packing'),
      value: OUTSTATUS.PACKING,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.PACKING, count.packingCount),
      dataCount: count.packingCount,
    },
    {
      label: i18n.t('common:tab.shipping'),
      value: OUTSTATUS.SHIPPING,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.SHIPPING, count.shippingCount),
      dataCount: count.shippingCount,
    },
    {
      label: i18n.t('common:tab.shipped'),
      value: OUTSTATUS.SHIPPED,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.SHIPPED, count.shippedCount),
      dataCount: count.shippedCount,
    },
    {
      label: i18n.t('common:tab.hold_for_error'),
      value: OUTSTATUS.HOLDFORERROR,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.HOLDFORERROR, count.holdForErrorCount),
      dataCount: count.holdForErrorCount,
    },
    {
      label: i18n.t('common:tab.canceled'),
      value: OUTSTATUS.CANCEL,
      onClick: () => {},
      badge: getBadge(OUTSTATUS.CANCEL, count.cancelCount),
      dataCount: count.cancelCount,
    },
  ];
};

export const bulkAction = (
  data,
  selectedIds,
  handleSelectionChange,
  setBannerText,
  setBannerSuccess,
  setBannerVisible,
  printerConfig,
) => {
  var status = new Set();
  var tracking = new Set();
  data.forEach((d) => {
    if (selectedIds.includes(d.orderShipmentId)) {
      status.add(d.orderShipmentStatus);
    }
    if (selectedIds.includes(d.orderShipmentId) && d.courierTrackingNumber) {
      tracking.add(d.courierTrackingNumber);
    }
  });

  if (status.size === 0) return [];
  if (onlyContains(status, [OUTSTATUS.HOLDFORERROR])) {
    return [
      {
        content: (
          <BulkButton
            icon={AlertTriangleIcon}
            content={i18n.t('outbound:request_address_issue')}
          />
        ),
        icon: AlertTriangleIcon,
        onAction: async () => {
          const confirmation = await issueConfirm({
            title: i18n.t('outbound:request_address'),
            content: i18n.t('outbound:request_address_issue'),
          });
          if (confirmation.confirmed) {
            try {
              const issues = selectedIds.map((id) => {
                return {
                  orderShipmentId: id,
                  holdReason: confirmation.value,
                };
              });
              const res = await orderAddressOMSNotifyBulk(issues);
              if (!isZeroObject(res)) {
                if (res.notIssued && res.notIssued > 0) {
                  setBannerText(
                    i18n.t('outbound:banner_text_fail', {
                      all: res.issued + res.notIssued,
                      issued: res.issued,
                      notIssued: res.notIssued,
                    }),
                  );
                  setBannerSuccess(false);
                } else {
                  setBannerText(
                    i18n.t('outbound:banner_text_success', {
                      all: res.issued + res.notIssued,
                      issued: res.issued,
                    }),
                  );
                  setBannerSuccess(true);
                }
                setBannerVisible(true);
              }
            } catch (err) {
              setBannerText(`Error : ${err}`);
            } finally {
              handleSelectionChange('page', false);
            }
          }
        },
      },
    ];
  }

  if (tracking.size === selectedIds.length) {
    return [
      {
        content: (
          <BulkButton
            icon={LabelPrinterIcon}
            content={i18n.t('outbound:reprint_tracking_label')}
          />
        ),
        icon: LabelPrinterIcon,
        onAction: async () => {
          const confirmation = await confirm({
            title: i18n.t('outbound:reprint_label_modal'),
            content: i18n.t('outbound:reprint_label_modal_sub'),
          });
          if (confirmation) {
            try {
              const ids = selectedIds.map((id) => {
                return {
                  orderShipmentId: id,
                };
              });
              const res = await getRePrintOutbound(ids);
              if (!isZeroObject(res)) {
                if (Number(res?.statuses?.notPrinted) && Number(res?.statuses?.notPrinted) > 0) {
                  setBannerText(
                    i18n.t('outbound:banner_text_fail_print', {
                      all: Number(res?.statuses?.printed) + Number(res?.statuses?.notPrinted),
                      printed: Number(res?.statuses?.printed),
                      notPrinted: Number(res?.statuses?.notPrinted),
                    }),
                  );
                  setBannerSuccess(false);
                } else {
                  setBannerText(
                    i18n.t('outbound:banner_text_success_print', {
                      all: Number(res?.statuses?.printed) + Number(res?.statuses?.notPrinted),
                      printed: Number(res?.statuses?.printed),
                    }),
                  );
                  setBannerSuccess(true);
                }
                setBannerVisible(true);
                await Promise.all(
                  res.printFiles.map(async (file) => {
                    await QZPrintFile(file, printerConfig);
                  }),
                );
              }
            } catch (err) {
              setBannerText(`Error : ${err}`);
            } finally {
              handleSelectionChange('page', false);
            }
          }
        },
      },
    ];
  }

  return [];
};
export const headers = () => {
  return [
    { title: i18n.t('common:field.order') },
    { title: i18n.t('common:field.customer') },
    { title: i18n.t('common:field.tote') },
    { title: i18n.t('common:field.products') },
    { title: i18n.t('common:field.note_to_location') },
    { title: i18n.t('common:field.ready_to_ship_date') },
    { title: i18n.t('common:field.courier') },
    { title: i18n.t('common:field.tracking_number') },
    { title: i18n.t('common:field.status') },
    { title: i18n.t('common:field.address_verify') },
    { title: i18n.t('common:field.shipped_date') },
  ];
};

export const initialFilters = {
  userId: { label: '', value: '' },
  tote: { label: '', value: '' },
};

export const filters = (state, setState) => [
  {
    key: 'tote',
    label: i18n.t('common:field.tote_number'),
    filter: (
      <TextFieldFilter
        label={i18n.t('common:field.tote_number')}
        value={state.tote.value}
        onEnter={(value) => {
          setState('tote', value);
        }}
      />
    ),
    shortcut: true,
    pinned: true,
  },
  {
    key: 'userId',
    label: i18n.t('common:field.customer'),
    filter: (
      <SingleSelectAutocomplete
        label={i18n.t('common:field.customer')}
        useOptions={useGetUsersWithParams}
        params={{
          pageSize: 5,
          pageNumber: 1,
          roleType: 'customer',
        }}
        searchParamName={'userName'}
        toOption={(d) => ({
          label: chooseLan(d.userFirstNameLocal, d.userFirstNameEnglish),
          value: d.userId,
        })}
        value={state.userId.value}
        onSelect={(value) => setState('userId', value)}
      />
    ),
    shortcut: true,
    pinned: true,
  },
];

export function disambiguateLabel(key, value) {
  switch (key) {
    case 'userId':
      return i18n.t('common:label.customer', { customer: value });
    case 'tote':
      return i18n.t('common:label.tote', { tote: value });
    default:
      return value;
  }
}
