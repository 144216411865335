const ROOTS = {
  auth: '/auth',
  dashboard: {
    root: '/dashboard',
    order: '/dashboard/order',
    purchasing: '/dashboard/purchasing',
    inbound: '/dashboard/inbound',
    outbound: '/dashboard/outbound',
    inventory: '/dashboard/inventory',
  },
};

export const PATHS = {
  home: '/',
  auth: {
    login: `${ROOTS.auth}/login`,
    email: `${ROOTS.auth}/email`,
    forgotPassword: `${ROOTS.auth}/forgot-password`,
    resetPassword: `${ROOTS.auth}/reset-password`,
    chooseLocation: `${ROOTS.auth}/choose-location`,
  },
  dashboard: {
    root: `${ROOTS.dashboard.root}/root`,
    order: {
      root: `${ROOTS.dashboard.order}`,
      order: {
        root: `${ROOTS.dashboard.order}/order`,
        add: `${ROOTS.dashboard.order}/order/new`,
        edit: (id) => `${ROOTS.dashboard.order}/order/edit/${id}`,
      },
      merge: `${ROOTS.dashboard.order}/merge`,
    },
    purchasing: {
      root: `${ROOTS.dashboard.purchasing}`,
      purchase_order: {
        root: `${ROOTS.dashboard.purchasing}/purchase-order`,
        add: `${ROOTS.dashboard.purchasing}/purchase-order/new`,
        edit: (id) => `${ROOTS.dashboard.purchasing}/purchase-order/edit/${id}`,
      },
      supplier: {
        root: `${ROOTS.dashboard.purchasing}/supplier`,
        add: `${ROOTS.dashboard.purchasing}/supplier/new`,
        edit: (id) => `${ROOTS.dashboard.purchasing}/supplier/edit/${id}`,
      },
    },
    inbound: {
      root: `${ROOTS.dashboard.inbound}`,
      inbound: {
        root: `${ROOTS.dashboard.inbound}/inbound`,
        edit: (id) => `${ROOTS.dashboard.inbound}/inbound/edit/${id}`,
        history: (id, purchaseOrderLine) =>
          `${ROOTS.dashboard.inbound}/inbound/edit/${id}/${purchaseOrderLine}`,
      },
      putaway: {
        root: `${ROOTS.dashboard.inbound}/putaway`,
      },
    },
    outbound: {
      root: `${ROOTS.dashboard.outbound}`,
      outbound: {
        root: `${ROOTS.dashboard.outbound}/outbound`,
        edit: (id) => `${ROOTS.dashboard.outbound}/outbound/edit/${id}`,
      },
      shipping: {
        scan: {
          root: `${ROOTS.dashboard.outbound}/shipping/scan`,
        },
        containers: {
          root: `${ROOTS.dashboard.outbound}/shipping/containers`,
          scan: `${ROOTS.dashboard.outbound}/shipping/containers/scan`,
          edit: (id) => `${ROOTS.dashboard.outbound}/shipping/containers/${id}`,
        },
      },
      picking: {
        root: `${ROOTS.dashboard.outbound}/picking`,
        total_picking: (shipmentPickingId) =>
          `${ROOTS.dashboard.outbound}/picking/total/${shipmentPickingId}`,
        wave_picking: (shipmentPickingId) =>
          `${ROOTS.dashboard.outbound}/picking/wave/${shipmentPickingId}`,
        sorting: (shipmentPickingId) =>
          `${ROOTS.dashboard.outbound}/picking/sort/${shipmentPickingId}`,
        tracking_picking: (shipmentPickingId) =>
          `${ROOTS.dashboard.outbound}/picking/tracking/${shipmentPickingId}`,
      },
      packing: {
        root: `${ROOTS.dashboard.outbound}/packing`,
        multi: (locationId, type, scanNumber) =>
          `${ROOTS.dashboard.outbound}/packing/multi/${locationId}/${type}/${scanNumber}`,
        single: (locationId, type, scanNumber) =>
          `${ROOTS.dashboard.outbound}/packing/single/${locationId}/${type}/${scanNumber}`,
      },
      tracking_label: {
        root: `${ROOTS.dashboard.outbound}/tracking-label`,
      },
    },
    inventory: {
      root: `${ROOTS.dashboard.inventory}`,
      products: {
        root: `${ROOTS.dashboard.inventory}/products`,
        add: `${ROOTS.dashboard.inventory}/products/new`,
        edit: (userId, countryCode, id) =>
          `${ROOTS.dashboard.inventory}/products/edit/${userId}/${countryCode}/${id}`,
      },
      stock: {
        root: `${ROOTS.dashboard.inventory}/stock`,
      },
      transfer: {
        root: `${ROOTS.dashboard.inventory}/transfer`,
        add: `${ROOTS.dashboard.inventory}/transfer/new`,
        edit: (transferOrderId) => `${ROOTS.dashboard.inventory}/transfer/edit/${transferOrderId}`,
      },
    },
  },
};
